import {Component, OnInit} from '@angular/core';
import {TitleService} from "../../services/title.service";
import {links} from "../../app-routing.module";
import {IAlbumImage} from "../album/album.component";

@Component({
             selector: 'app-projects',
             templateUrl: './projects.component.html',
             styleUrls: ['./projects.component.scss']
           })
export class ProjectsComponent implements OnInit {
  links = links;


  readonly album: IAlbumImage[] = [
    {
      caption: "Modenschau im JUKS",
      src: "/projects/DSC_0487.JPG"
    },
    {
      caption: "Kostenlose Tombola",
      src: "/projects/DSC_0128.JPG"
    }
  ]

  constructor(titleService: TitleService) {
    titleService.setTitle("Projekte")
  }

  ngOnInit(): void {
  }

}
