<form (submit)="save()" [formGroup]="form" autocomplete="off" novalidate>
  <mat-form-field appearance="fill">
    <mat-label>Titel</mat-label>
    <input matInput type="email" [formControl]="formControls.title" required>
    <mat-error *ngIf="formControls.title.invalid">Benutzername is erforderlich</mat-error>
  </mat-form-field>
  <angular-editor [formControl]="formControls.content" [config]="editorConfig"></angular-editor>
  <mat-form-field appearance="fill">
    <mat-label>Angebotszeitraum</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate placeholder="Start" [formControl]="formControls.startDate">
      <input matEndDate placeholder="Ende" [formControl]="formControls.endDate">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
  <button mat-flat-button [disabled]="isProcessing">
    Speichern
    <mat-progress-bar *ngIf="isProcessing" mode="indeterminate"></mat-progress-bar>
  </button>
</form>
