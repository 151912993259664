<h2>Wir stellen uns vor</h2>
<p>Seit Oktober 2009 sind wir ein eigetragener gemeinnütziger Verein, der ausschließlich gemeinnützige Zwecke verfolgt.
  Hierzu zählen die Kinder-, Jugend- und Altenhilfe sowie die Völkerverständigung.</p>
<p>Der Zweck wird insbesondere durch die Projekt-, Kurs- und Hilfsangebote für Kinder und Jugendliche sowie Senior:innen erfüllt. Hierzu
  gehört vor allem die Weitergabe traditioneller Fähigkeiten wie z.B. Kochen und Nähen.</p>
<p>Wir übernehmen Kosten zur Durchführung von Veranstaltungen und Kursen. Ebenso unterstützen wir viele
  Schenefelder Einrichtungen in der Sozialarbeit. Die Hilfe soll vielen Kindern und Jugendlichen in Schenefeld
  zugute kommen.</p>
<p>Alle Einnahmen, abzüglich Miete und sonstigen Betriebskosten, werden ausschließlich für soziale Projekte verwendet.</p>
<h4 style="text-decoration:underline;font-weight: bold;">Alle Helfer*innen arbeiten ehrenamtlich.</h4>
<app-album [album]="album"></app-album>
