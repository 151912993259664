import { Component } from '@angular/core';
import {TitleService} from "../../services/title.service";

@Component({
  selector: 'app-data-privacy',
  templateUrl: './data-privacy.component.html',
  styleUrls: ['./data-privacy.component.scss']
})
export class DataPrivacyComponent {

  constructor(titleService: TitleService) {
    titleService.setTitle("Datenschutz")
  }

}
