<form (submit)="send()" [formGroup]="form" autocomplete="off" novalidate>
  <mat-form-field appearance="fill">
    <mat-label>Ihr Name</mat-label>
    <input matInput type="email" [formControl]="formControls.name" required>
    <mat-error *ngIf="formControls.name.invalid">Name ist erforderlich</mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Ihre Mailadresse</mat-label>
    <input matInput type="email" [formControl]="formControls.mail" required>
    <mat-error *ngIf="formControls.mail.invalid">Mailadresse ist erforderlich</mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Ihre Nachricht</mat-label>
    <textarea matInput [formControl]="formControls.message" required></textarea>
    <mat-error *ngIf="formControls.message.invalid">Nachricht ist erforderlich</mat-error>
  </mat-form-field>
  <p *ngIf="isSuccess">Die Nachricht wurde erfolgreich gesendet</p>
  <p *ngIf="hasError">Die Nachricht konnte nicht gesendet werden. Bitte versuchen Sie es später erneut oder senden sie eine Mail an <a href="mailto:info@gluecksgriff-schenefeld.de">info@gluecksgriff-schenefeld.de</a>.</p>
  <button mat-flat-button [disabled]="isSending">
    Absenden
    <mat-progress-bar *ngIf="isSending" mode="indeterminate"></mat-progress-bar>
  </button>
</form>
