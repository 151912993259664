import {AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, ViewEncapsulation} from '@angular/core';
import {IAlbum, IEvent, Lightbox, LightboxEvent} from "ngx-lightbox";
import {filter, takeWhile} from "rxjs/operators";
import {Observable} from "rxjs";
import {LIGHTBOX_EVENT} from "ngx-lightbox/lightbox-event.service";

export interface IAlbumImage {
  src: string;
  caption: string;
}

@Component({
             selector: 'app-album',
             encapsulation: ViewEncapsulation.None,
             templateUrl: './album.component.html',
             styleUrls: ['./album.component.scss'],
             host: {
               class: "album"
             }
           })
export class AlbumComponent implements OnDestroy, AfterViewInit {
  private _lightboxOpen = false;
  private index = -1;
  private _destroyed = false;
  private _mouseIsOver = false;
  private readonly _lightbox: Lightbox;

  constructor(private readonly _elementRef: ElementRef, lightbox: Lightbox, lightboxEvent: LightboxEvent) {
    this._lightbox = lightbox;
    const lightboxEvents$: Observable<IEvent> =
      (lightboxEvent.lightboxEvent$ as Observable<IEvent>)
        .pipe(takeWhile(() => !this._destroyed));
    lightboxEvents$
      .pipe(filter(({id, data}) => id === LIGHTBOX_EVENT.CHANGE_PAGE && data >= 0))
      .subscribe(({data}) => {
        this.index = data;
        void this.showImage()
      });
    lightboxEvents$
      .pipe(filter(({id}) => id === LIGHTBOX_EVENT.CLOSE))
      .subscribe(() => this._lightboxOpen = false);
    lightboxEvents$
      .pipe(filter(({id}) => id === LIGHTBOX_EVENT.OPEN))
      .subscribe(() => this._lightboxOpen = true);
  }

  private _album: IAlbum[] = [];

  @Input()
  set album(album: IAlbumImage[]) {
    if (!album) {
      this._album = [];
      return;
    }
    this._album = album.map(image => {
      return {
        src: image.src,
        caption: image.caption,
        thumb: AlbumComponent.getThumbnailPath(image)
      };
    });
  }

  private static getThumbnailPath(image: IAlbumImage): string {
    return `thumbs/736x400/${image.src}`.replace(/\/+/g, "/");
  }

  @HostListener("mouseenter")
  onMouseEnter() {
    this._mouseIsOver = true;
  }

  @HostListener("mouseleave")
  onMouseLeave() {
    this._mouseIsOver = false;
  }

  async showNextImage() {
    if (this._album.length < 1 || this._destroyed) {
      return;
    }
    if (this._mouseIsOver || this._lightboxOpen) {
      window.setTimeout(() => this.showNextImage(), 500);
      return;
    }
    this.index = (this.index + 1) % this._album.length;
    this.cleanupPreviousImage();
    await this.showImage();
    if (this._album.length > 1)
      window.setTimeout(() => {
        this.showNextImage();
      }, 5000);
  }

  ngOnDestroy(): void {
    this._destroyed = true;
  }

  ngAfterViewInit(): void {
    void this.showNextImage();
  }

  open(): void {
    this._lightbox.open(this._album, this.index);
  }

  private async showImage(): Promise<void> {
    const image = this._album[this.index];
    await this.preloadImage(image);
    this.insertImageHtml(image);
  }

  private insertImageHtml(image: IAlbum): void {
    const div = document.createElement("div");
    div.classList.add("gallery-image");

    const img = document.createElement("img");
    img.src = image.thumb;
    img.onclick = () => this.open();
    div.append(img);

    this._elementRef.nativeElement.append(div);

    if (image.caption) {
      const caption = document.createElement("div");
      caption.classList.add("caption");
      caption.innerHTML = image.caption;
      caption.style.width = img.getBoundingClientRect().width + "px";
      caption.onclick = () => this.open();
      div.append(caption);
    }

    6
  }

  private preloadImage(image: IAlbum): Promise<void> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve();
      img.onerror = () => reject();
      img.src = image.thumb;
    });
  }

  private cleanupPreviousImage(): void {
    const children = this._elementRef.nativeElement.children;
    if (children.length > 2) {
      this._elementRef.nativeElement.removeChild(children[0]);
    }
  }

  close(): void {
    this._lightbox.close();
  }
}
