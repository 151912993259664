import {Component} from '@angular/core';
import {Offer, OfferApiClient} from "../../../api";
import {ActivatedRoute, Router} from "@angular/router";
import {TitleService} from "../../services/title.service";
import {LoginService, loginStatus} from "../../services/login.service";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-offer-list',
  templateUrl: './offer-list.component.html',
  styleUrls: ['./offer-list.component.scss']
})
export class OfferListComponent {
  private readonly _offerApiClient: OfferApiClient;
  private readonly _router: Router;
  offers: Offer[] = [];
  readonly isLoggedIn$: Observable<boolean>;

  constructor(offerApiClient: OfferApiClient, router: Router,titleService: TitleService, loginService: LoginService) {
    this._offerApiClient = offerApiClient;
    this._router = router;
    titleService.setTitle("Angebote bearbeiten")
    this._loadOffers();
    this.isLoggedIn$ = loginService.loginStatus$.pipe(map(s => s === "loggedIn"));
  }

  async deleteOffer(id: number) {
    await this._offerApiClient.deleteOffer(id).toPromise();
    this._loadOffers();
  }

  private _loadOffers(): void {
    this._offerApiClient
        .getOffers(false)
        .subscribe(offers => {
          this.offers = offers;
        })
  }
}
