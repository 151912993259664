<h1>Wie funktioniert unser Glücksgriff?</h1>
<p>Der Verein darf zur zur Beschaffung von Mitteln Second-Hand-Läden betreiben. Alle Artikel, die in unseren Geschäften
  verkauft werden, sind Spenden. Die Spendenbereitschaft ist nach wie vor sehr groß, so dass wir täglich neue Waren
  erhalten.
</p>
<p>Folgende Sachspenden nehmen wir gerne entgegen:</p>
<ul class="smiley-list">
  <li>Taschen und Schuhe</li>
  <li>Neue und getragene Damen-, Herren- und Kinderkleidung in sauberem Zustand</li>
  <li>Hausratsgegenstände</li>
  <li>Tisch- und Bettwäsche</li>
  <li>Schmuck</li>
  <li>Spiele und Spielsachen
  </li>
</ul>
<p>
  Aus Platzgründen können wir u.a. keine Möbel, Teppiche und Bilder/Bilderrahmen annehmen
</p>
<p>
  Die Sachspenden werden von unseren zuständigen Kompetenzteams gesichtet und ausgepreist. Somit befindet sich unsere
  Ware in einem ordentlichem Zustand. Ware, die wir nicht verkaufen können, geben wir an den gemeinnützigen Verein
  <a href="https://www.kleiderkammer-wilhelmsburg.de/" target="_blank">"Kleiderkammer Wilhelmsburg"</a> weiter.
</p>
<h4>So funktioniert der soziale Kreislauf!</h4>
<app-album [album]="album"></app-album>
