import { Component, OnInit } from '@angular/core';
import {UsersApiClient} from "../../../api";
import {Router} from "@angular/router";
import {LoginService} from "../../services/login.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TitleService} from "../../services/title.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  readonly formControls = {
    user: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  };

  readonly form = new FormGroup(this.formControls)

  hasLoginError = false;
  isLoggingIn = false;
  private readonly _loginService: LoginService;
  private _router: Router;

  constructor(loginService: LoginService, router: Router,titleService: TitleService) {
    this._loginService = loginService;
    this._router = router;
    titleService.setTitle("Anmelden")
  }

  async login() {
    if (!this.form.valid)
      return;
    this.hasLoginError = false;
    const user = this.formControls.user.value as string;
    const password = this.formControls.password.value as string;
    const loginSuccessful = await this._loginService.login(user, password);
    if (loginSuccessful)
      await this._router.navigateByUrl("/");
    else
      this.hasLoginError = true;
  }

  ngOnInit(): void {
  }

}
