import {Component, OnInit} from '@angular/core';
import {Offer, OfferApiClient} from "../../../api";
import {TitleService} from "../../services/title.service";
import {IAlbumImage} from "../album/album.component";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  offer?: Offer;
  readonly album: IAlbumImage[] = [
    {
      caption: "",
      src: "/10JahresFeier/DSC_0123.JPG"
    }
  ];

  constructor(offerApiClient: OfferApiClient, titleService: TitleService) {
    offerApiClient
      .getFirstValidOffer()
      .subscribe(offer => {
        this.offer = offer;
      });
    titleService.setTitle()
  }

  ngOnInit(): void {
  }

}
