import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {LoginService} from "./services/login.service";
import {first} from "rxjs/operators";
import {links} from "./app-routing.module";

@Injectable()
export class loginRedirector implements CanActivate {
  private readonly _loginService: LoginService;
  private readonly _router: Router;

  constructor(loginService: LoginService, router: Router) {
    this._loginService = loginService;
    this._router = router;

  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const isUserLoggedIn = await this._loginService.loginStatus$.pipe(first()).toPromise();
    if (isUserLoggedIn === "loggedIn") {
      return true;
    }
    return this._router.parseUrl(links.login);
  }
}
