<h1>Was können SIE für uns tun?</h1>
<ul>
  <li>Weiterhin Sachspenden in <a [routerLink]="'/' + menuLinks.route">unseren Geschäften</a> abgeben</li>
  <li>Weiterhin im Glücksgriff einkaufen</li>
  <li>Weiterhin für uns werben</li>
  <li>
    Bei uns mitarbeiten. Wir bauchen dringend jüngere Helfer:innen <br/>
    In Unserer Mitte ist noch Platz!
  </li>
  <li>Sie können auch für 25€ jährlich Vereinsmitglied werden</li>
  <li>
    Wir freuen uns auch über Geldspenden (mit Spendenbescheinigung)
    <fieldset>
      <legend>Bankverbindung</legend>
      <table class="borderless">
        <tr>
          <td>Bank</td>
          <td>Hamburger Sparkasse GmbH</td>
        </tr>
        <tr>
          <td>IBAN</td>
          <td>DE 22 2005 0550 1501 0926 78</td>
        </tr>
        <tr>
          <td>BIC</td>
          <td>HASPDEHHXXX</td>
        </tr>
      </table>
    </fieldset>
  </li>
</ul>
<app-album [album]="album1"></app-album>
<div style="display:flex; justify-content: space-around;">
  <p>Vereinsvorsitzende:<br/>
    Ingrid Pöhland<br/>
    Tel.: <a href="tel:0408305524">040 830 5524</a><br>
    22869 Schenefeld<br>
    Jahnstraße 73<br>
    <a href="mailto:mail@ingridpehland.de">mail@ingridpoehland.de</a>
  </p>
  <p>Stellvertreter:<br/>
    Peter Bluhm<br/>
    Tel. <a href="tel:0408300329">040 830 0329</a><br/>
    22869 Schenfeld<br/>
    <a href="mailto:e.bluhm@kabelmail.de">e.bluhm@kabelmail.de</a>
  </p>
</div>
<app-album [album]="album2"></app-album>
<p></p>
<strong style="text-align: center; display:block;">Vielen Dank für ihre Unterstüztung und Interesse!</strong>
