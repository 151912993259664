import {Component, OnInit} from '@angular/core';
import {TitleService} from "../../services/title.service";
import {IAlbumImage} from "../album/album.component";

@Component({
  selector: 'app-repair-cafe',
  templateUrl: './repair-cafe.component.html',
  styleUrls: ['./repair-cafe.component.scss']
})
export class RepairCafeComponent implements OnInit {

  readonly album: IAlbumImage[] = [
    {
      caption: "Das RepairCafé wurde am 7. M&auml;rz 2020 zum ersten Mal im JUKS organisiert",
      src: "/repaircafe/DSC_0063.JPG"
    },
    {
      caption: "Aktiv beteiligt waren von Beginn an mehr als  30 Experten*Innen",
      src: "/repaircafe/DSC_0085.JPG"
    },
    {
      caption: "F&uuml:r die Organisation, Dokumentation oder rechtliche Fragen kommt es auf jede*n an!",
      src: "/repaircafe/DSC_0039.JPG"
    },
    {
      caption: "F&uuml:r die Organisation, Dokumentation oder rechtliche Fragen kommt es auf jede*n an!",
      src: "/repaircafe/DSC_0036.JPG"
    },
    {
      caption: "Experten bei der Arbeit und Unterst&uuml:tzung im Elektrobereich.",
      src: "/repaircafe/DSC_0114.JPG"
    }
  ]


  constructor(titleService: TitleService) {
    titleService.setTitle("Repair Café")
  }

  ngOnInit(): void {
  }

}
