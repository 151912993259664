import {Injectable} from '@angular/core';
import {UsersApiClient} from "../../api";
import {ReplaySubject} from "rxjs";

export type loginStatus = "loggedIn" | "loggedOut";

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private _usersApiClient: UsersApiClient;

  private readonly _loginStatusSubject = new ReplaySubject<loginStatus>(1);
  readonly loginStatus$ = this._loginStatusSubject.asObservable();

  constructor(usersApiClient: UsersApiClient) {
    this._usersApiClient = usersApiClient;
    this._usersApiClient
      .isLoggedIn()
      .subscribe((isLoggedIn) => this._loginStatusSubject.next(isLoggedIn ? "loggedIn" : "loggedOut"));
  }

  async login(user: string, password: string) {
    try {
      const loggedIn = await this._usersApiClient.login(user, password).toPromise();
      if (!loggedIn)
        return false;
      this._loginStatusSubject.next("loggedIn");
      return true;
    } catch (e) {
      return false;
    }
  }

  async logout() {
    try {
      await this._usersApiClient.logout().toPromise();
      this._loginStatusSubject.next("loggedOut");
      return true;
    } catch (e) {
      return false;
    }
  }


}
