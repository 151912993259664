import {Component, OnInit} from '@angular/core';
import {links} from "../../app-routing.module";
import {TitleService} from "../../services/title.service";
import {IAlbumImage} from "../album/album.component";

@Component({
             selector: 'app-support-us',
             templateUrl: './support-us.component.html',
             styleUrls: ['./support-us.component.scss']
           })
export class SupportUsComponent implements OnInit {

  readonly menuLinks = links;

  readonly album1: IAlbumImage[] = [
    {
      caption: "",
      src: "/unterstuetzenSieUns/DSC_0096.JPG",
    }

  ]

  readonly album2: IAlbumImage[] = [
    {
      caption: "10 Jahre Geburtstag Glücksgriff",
      src: "/unterstuetzenSieUns/DSC_0088.JPG",
    }

  ]

  constructor(titleService: TitleService) {
    titleService.setTitle("Unterstützen Sie uns")
  }

  ngOnInit(): void {
  }

}
