<h1>Schenefeld hat ein Repair Caf&eacute;!</h1>
<p>Seit dem 7. M&auml;rz 2020 gibt es in Schenefeld ein Repair Caf&eacute;.</p>
<p>Gl&uuml;cksgriff ergriff die Initiative. In Zusammenarbeit mit dem <a href="https://www.juks-schenefeld.de/"
                                                                         target="_blank">JUKS
  Schenefeld</a> wurde die Idee umgesetzt. Alle 2
  Monate wird es im JUKS organisiert. 30 ExpertenInnen wirken ehrenamtlich mit.</p>
<p>Die Idee des Repair-Caf&eacute;-Konzepts kommt aus den Niederlanden. Es wurde 2009 von Martine Postma (Journalistin)
  entwickelt. Das erste Repair Caf&eacute; fand am 18.10.2009 in Amsterdam statt, wo die Reparaturexperten sie mit
  Werkzeug und Material erwarteten. Inzwischen ist die Stiftung <a href="https://www.repaircafe.org/de/"
                                                                   target="_blank">&bdquo;Stichting
    Repair
    Caf&eacute;&ldquo;</a> gegr&uuml;ndet
  worden. Diese Stiftung unterst&uuml;tzt seit 2011 lokale Gruppen (z.B. Schenefeld) und hat sich folgende Ziele
  gesetzt</p>
<ol>
  <li>Reparieren auf moderne Weise und auf lokaler Ebene wieder in die Gesellschaft zu tragen&nbsp;</li>
  <li>Reparaturwissen zu erhalten und zu verbreiten</li>
  <li>Den sozialen Zusammenhalt der Gemeinschaft f&ouml;rdern, indem Nachbarn mit verschiedenen sozialen Hintergr&uuml;nden
    und Interessen miteinander in Kontakt gebracht werden.
  </li>
</ol>
<app-album [album]="album"></app-album>

<h2>Warum ein Repair Caf&eacute;?</h2>
<p>Die westliche Gesellschaft ist auf Konsum ausgerichtet. Wir kaufen immer mehr und werfen entsprechend auch immer mehr
  weg. Die Nutzungsdauer unserer Luxusg&uuml;ter wird immer k&uuml;rzer.</p>
<p>Sobald diese irgendwelche M&auml;ngel aufweisen&nbsp;wie z.B.</p>
<ul>
  <li>Ein Stuhl mit wackelndem Bein</li>
  <li>Ein CD-Spieler mit defekter Klappe</li>
  <li>Ein Wollpullover mit Loch am Ellbogen (usw.)</li>
</ul>
<p>werfen wir sie weg und kaufen ein neues Produkt.&nbsp;</p>
<p>Viele Menschen haben vergessen, dass man alte Gegenst&auml;nde durchaus reparieren kann. Und sie wissen auch nicht
  mehr, wie man das macht. Das Wissen hier&uuml;ber verschwindet zusehends. Menschen, die sich mit diesen praktischen
  Dingen sehr wohl noch auskennen (Handwerker, &auml;ltere Menschen) werden von der Gesellschaft nicht immer gleicherma&szlig;en
  wertgesch&auml;tzt. Ihr Wissen wird kaum oder gar nicht genutzt. Und das, obwohl genau diese Menschen eine Menge zur
  Nachhaltigkeit in unserer Gesellschaft beitragen k&ouml;nnen.&nbsp;</p>
<p>Sie daf&uuml;r zu gewinnen, bei Reparaturtreffen anderen Hilfestellung zu geben, bringt gleich mehrere positive
  Effekte auf einmal mit sich: Wertvolles Praxiswissen wird weitergegeben. Die Grundstoff- und Energiemenge f&uuml;r die
  Herstellung neuer Produkte wird reduziert. Und nicht zuletzt hilft Reparieren anstatt Wegwerfen bei der Reduzierung
  des CO<sub>2</sub>-Austo&szlig;es.</p>
<p><em>Im Repair Caf&eacute; lernen Menschen, ihre Dinge mit anderen Augen zu sehen und sie wieder wertzusch&auml;tzen.
  Reparieren leistet einen Beitrag zur Mentalit&auml;tsver&auml;nderung, die erforderlich daf&uuml;r ist, Menschen f&uuml;r
  eine nachhaltige Gesellschaft zu begeistern.</em></p>
<p></p>
<h2>Welche Reparaturen sind m&ouml;glich?</h2>
<p>Mehr als 30 Frauen und M&auml;nner sind bereit, Reparaturarbeiten vorzunehmen.<br/>
<p>Folgende Bereiche bieten wir an</p>
<ul>
  <li>Elektro, Electronic und PC</li>
  <li>Holz, Metall bzw. Kunststoff&nbsp;</li>
  <li>Schneiderei</li>
  <li>Uhren</li>
  <li>Puppendoktor</li>
  <li>Fahrrad</li>
  <li>Organisation (Empfang, Registrierung)</li>
  <li>Caf&eacute; mit Wartebereich&nbsp;</li>
  <li>...</li>
</ul>
<p></p>
<h2>Was ist noch wichtig?</h2>
<ul>
  <li>Grunds&auml;tzlich sind alle Reparaturen kostenlos. &Uuml;ber eine angemessene Spende freuen wir uns!
  </li>
  <li>Mehr als 3 Reparaturteile pro Besucher können nicht durchgeführt werden!</li>
  <li>Notwendige Ersatzteile m&uuml;ssen bezahlt werden.</li>
  <li>Bewirtung evtl. wegen der Corona-Auflagen nicht m&ouml;glich; im Normalfall gibt es &nbsp;Kaffee, Tee, Kuchen und
    auch hierbei freuen wir uns &uuml;ber eine Spende.
  </li>
</ul>
<p></p>
<h2>Wann und wo?</h2>
<table>
  <tr>
    <td>Veranstaltungsort</td>
    <td><a href="https://goo.gl/maps/Qj3PcrtddwkrupYD8" target="_blank">JUKS in 22869 Schenefeld, Osterbrooksweg 25</a>
    </td>
    <td></td>
  </tr>
  <tr>
    <td>Uhrzeit</td>
    <td>Sonnabends<br/>
      Beginn 11 Uhr<br/>
      Annahmenschluß 15:30 Uhr
    </td>
  </tr>
  <tr>
    <td>Termine</td>
    <td>Werden aktuell auf der Webseite bekannt gegeben</td>
  </tr>
</table>
<p>Besuchen Sie uns und bringen Sie Nachbarn, Freunde oder Interessierte mit.</p>
<p>Wir freuen uns auf Sie.</p>
<p>Das Repair-Caf&eacute;-Team</p>
