import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AboutUsComponent} from './components/about-us/about-us.component';
import {HomeComponent} from './components/home/home.component';
import {HowItWorksComponent} from './components/how-it-works/how-it-works.component';
import {ProjectsComponent} from './components/projects/projects.component';
import {RouteComponent} from './components/route/route.component';
import {SupportUsComponent} from './components/support-us/support-us.component';
import {LoginComponent} from "./components/login/login.component";
import {EditOfferComponent} from "./components/edit-offer/edit-offer.component";
import {ContactComponent} from "./components/contact/contact.component";
import {RepairCafeComponent} from "./components/repair-cafe/repair-cafe.component";
import {ImpressComponent} from "./components/impress/impress.component";
import {UserManagementComponent} from "./components/user-management/user-management.component";
import {OfferListComponent} from "./components/offer-list/offer-list.component";
import {DataPrivacyComponent} from "./components/data-privacy/data-privacy.component";
import {RepairCafeDatesComponent} from "./components/repair-cafe-dates/repair-cafe-dates.component";
import {loginRedirector} from "./login-redirector.service";

export const links = {
  home: "",
  aboutUs: "ueber-uns",
  howItWorks: "wie-funktioniert-gluecksgriff",
  projects: "projekte",
  route: "wo-sie-uns-finden",
  supportUs: "unterstuetzen-sie-uns",
  repairCafe: "repair-cafe",
  login: "anmelden",
  editOffer: "angebote",
  contact: "kontakt",
  impress: "impressum",
  userManagement: "benutzer",
  dataPrivacy: "datenschutz",
  repairCafeDates: "repair-cafe/termine"
}


const routes: Routes = [
  {
    path: links.home,
    pathMatch: "full",
    component: HomeComponent,
  },
  {
    path: links.aboutUs,
    component: AboutUsComponent
  },
  {
    path: links.howItWorks,
    component: HowItWorksComponent
  },
  {
    path: links.projects,
    component: ProjectsComponent
  },
  {
    path: links.route,
    component: RouteComponent
  },
  {
    path: links.supportUs,
    component: SupportUsComponent
  },
  {
    path: links.login,
    component: LoginComponent,
  },
  {
    path: links.editOffer + "/:id",
    component: EditOfferComponent,
    canActivate: [loginRedirector]
  },
  {
    path: links.editOffer,
    component: OfferListComponent
  },
  {
    path: links.contact,
    component: ContactComponent
  },
  {
    path: links.repairCafe,
    component: RepairCafeComponent
  },
  {
    path: links.impress,
    component: ImpressComponent
  },
  {
    path: links.dataPrivacy,
    component: DataPrivacyComponent
  },
  {
    path: links.userManagement,
    component: UserManagementComponent,
    canActivate: [loginRedirector]
  },
  {
    path: links.repairCafeDates,
    component: RepairCafeDatesComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
