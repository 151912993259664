<h2>Der Verein unterstützt viele Projekte</h2>
<ul>
  <li>Seit mehr als 10 Jahren werden wöchentlich mehr als 200kg Äpfel an die Schenefelder Schulen und einige Kindergärten geliefert.
    Damit ermöglichen wir eine tägliche kostenlose "Vitaminspritze"! Ein Biobauer liefert die Äpfel an!
  </li>
  <li>Seit 2010 wird an der Gemeinschaftschule der Kochkurs "Gutes Essen" angeboten. Im freiwilligen Nachmittagsunterricht
    können junge Menschen gesundes Kochen und Backen erlernen.
  </li>
  <li>Kinder und Handarbeit - geht das? Seit 2011 wird das "Kleine Modeatelier" in der Gemeinschaftsschule angeboten.
    Viele Kinder haben, unter Anleitung einer Schneiderin, eigene Kleidungsstücke entworfen und
    genäht.
  </li>
  <li>An beiden Grundschulen wird seit Herbst 2010 für alle Schüler:innnen Kinder das Lernen mit der <a
    href="https://www.klasse2000.de/" target="_blank">"Klasse
    2000"</a>
    umgesetzt. Von der ersten bis zur vierten Klasse erhalten die Kinder zusätzlichen Unterricht z.B. zu den Themen
    Gewaltprävention, Suchtprävention, Körperhaltung, Gesunde Ernährung u.v.m.
  </li>
  <li>Finanzielle Unterstützung anderer Schenefelder Vereine und Gruppen sowie Sozialeinrichtungen</li>
  <li>Einrichtung mit Anschlußfinanzierung des <a [routerLink]="links.repairCafe">Repair-Cafés Schenefeld</a></li>
  <li>Andere kleinere Projekte werden weiterhin unterstützt und neue Projekte sind geplant.</li>
</ul>
<app-album [album]="album"></app-album>
