import {Component, OnInit} from '@angular/core';
import {ContentApiClient, MailApiClient} from "../../../api";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TitleService} from "../../services/title.service";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  isSending = false;
  hasError = false;
  isSuccess = false;
  private readonly _mailApiClient: MailApiClient;

  readonly formControls = {
    name: new FormControl('', [Validators.required]),
    mail: new FormControl('', [Validators.required, Validators.email]),
    message: new FormControl("", [Validators.required])
  };

  readonly form = new FormGroup(this.formControls)

  constructor(mailApiClient: MailApiClient,titleService: TitleService) {
    this._mailApiClient = mailApiClient;
    titleService.setTitle("Kontakt")
  }

  ngOnInit(): void {
  }

  async send() {
    this.hasError = false;
    this.isSuccess = false;
    if (!this.form.valid)
      return;
    this.isSending = true;

    try {
      await this._mailApiClient.sendContactMail(this.formControls.mail.value, this.formControls.mail.value, this.formControls.message.value).toPromise();
      this.form.reset();
      this.isSuccess = true;
    } catch {
      this.hasError = true;
    }
    this.isSending = false;
  }

}
