import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {TitleService} from "../../services/title.service";

@Component({
             selector: 'app-repair-cafe-dates',
             templateUrl: './repair-cafe-dates.component.html',
             styleUrls: ['./repair-cafe-dates.component.scss']
           })
export class RepairCafeDatesComponent implements OnInit {

  dates: Date[] = [];

  readonly formControls = {
    date: new FormControl('', [Validators.required]),
  }
  readonly form = new FormGroup(this.formControls);

  constructor(router: Router, titleService: TitleService) {
    titleService.setTitle("Repair Cafe Termine bearbeiten")
  }

  ngOnInit(): void {
  }

  removeDate(index: number) {
    this.dates.splice(index, 1);
  }

  addDate() {
    if (!this.form.valid)
      return;
    const date = this.formControls.date.value as Date;
    for (let i = 0; i < this.dates.length; i++) {

    }
  }


}
