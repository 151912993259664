import { Injectable } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  private readonly _title: Title;

  constructor(title: Title) {
    this._title = title;
  }

  setTitle(title?: string) {
    const appendix: string = `Glücksgriff - der soziale Kreislauf e.V.`;
    if (title)
      this._title.setTitle(`${title} – ${appendix}`)
    else
      this._title.setTitle(appendix);
  }


}
