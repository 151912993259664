<a *ngIf="isLoggedIn$ | async" mat-flat-button routerLink="neu">Neues Angebot</a>
<ng-container *ngFor="let offer of offers">
  <hr/>
  <p *ngIf="offer.validFrom || offer.validUntil">{{offer.title}} ({{offer.validFrom | date}} - {{offer.validUntil | date}})</p>
  <p *ngIf="!offer.validFrom && !offer.validUntil">{{offer.title}}</p>
  <div [innerHTML]="offer.htmlContent"></div>
  <ng-container *ngIf="isLoggedIn$ | async">
    <a mat-flat-button [routerLink]="offer.id!.toString()">Bearbeiten</a>
    <button mat-flat-button class="warning" (click)="deleteOffer(offer.id!)">Löschen</button>
  </ng-container>
</ng-container>
