import {Component, OnInit} from '@angular/core';
import {TitleService} from "../../services/title.service";
import {IAlbumImage} from "../album/album.component";

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  readonly album: IAlbumImage[] = [
    {
      caption: "Unser Team beim gemeinsamen Ausflug nach Schwerin",
      src: "/team/DSC_0051.JPG"
    },
    // {
    //   caption: "Montagsgruppe in der Lornsenstrasse",
    //   src: "/team/DSC_0018.JPG"
    // },
    {
      caption: "Kompetenzteam Kinderkleidung",
      src: "/team/DSC_0028.JPG"
    },
    // {
    //   caption: "Verkaufsteam Freitagvormittag in der Lornsenstrasse",
    //   src: "/team/DSC_0033.JPG"
    // },
    {
      caption: "Sortier- und Lagerhaltung im Postgebäude",
      src: "/team/DSC_0027.JPG"
    },
    {
      caption: "Fahrdienst zwischen den beiden Geschäften ist notwendig",
      src: "/team/DSC_0016.JPG"
    }
  ]

  constructor(titleService: TitleService) {
    titleService.setTitle("Über uns")
  }

  ngOnInit(): void {
  }

}
