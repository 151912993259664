<form (submit)="login()" [formGroup]="form" autocomplete="off" novalidate>
  <mat-form-field appearance="fill">
    <mat-label>Benutzername</mat-label>
    <input matInput type="email" [formControl]="formControls.user" required>
    <mat-error *ngIf="formControls.user.invalid">Benutzername ist erforderlich</mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Password</mat-label>
    <input matInput type="password" [formControl]="formControls.password" required>
    <mat-error *ngIf="formControls.password.invalid">Passwort ist erforderlich</mat-error>
  </mat-form-field>
  <button mat-flat-button [disabled]="isLoggingIn">
    Anmelden
    <mat-progress-bar *ngIf="isLoggingIn" mode="indeterminate"></mat-progress-bar>
  </button>
</form>
