<h1>Impressum</h1>

<h2>Angaben gemäß § 5 TMG:</h2>
<p>Glücksgriff e.V.<br>
  Lornsenstraße 86<br>
  22869 Schenefeld</p>
<h2>Vertreten durch:</h2>
<p>Glücksgriff e.V<br>
  Vertreten durch Ingrid Pöhland</p>
<h2>Kontakt:</h2>
<p>Telefon: <a href="tel:00494084055220">+49 (0) 40 840 55 220</a><br>
  E-Mail: info@gluecksgriff-schenefeld.de</p>
<h2>Registereintrag:</h2>
<p>Eintragung im Vereinsregister.<br>
  Registergericht:Amtsgericht Pinneberg<br>
  Registernummer: VR 1486 PI / Oktober 2009</p>
<h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h2>
<p>Inrid Pöhland<br>
  Lornsenstraße 86<br>
  22869 Schenefeld</p>
<h2>Streitschlichtung</h2>
<p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
  teilzunehmen.</p>
<h3>Haftung für Inhalte</h3>
<p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
  Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte
  oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
  Tätigkeit hinweisen.</p>
<p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
  hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
  Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte
  umgehend entfernen.</p>
<h3>Haftung für Links</h3>
<p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb
  können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets
  der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
  Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
  erkennbar.</p>
<p>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
  Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
  entfernen.</p>
<h3>Urheberrecht</h3>
<p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
  Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des
  Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien
  dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.</p>
<p>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
  beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
  Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
  Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>

<hr>
<p>Bankverbindungen:</p>
<table class="borderless">
  <tr>
    <td>Bank</td>
    <td>Hamburger Sparkasse GmbH</td>
  </tr>
  <tr>
    <td>IBAN</td>
    <td>DE 22 2005 0550 1501 0926 78</td>
  </tr>
  <tr>
    <td>BIC</td>
    <td>HASPDEHHXXX</td>
  </tr>
</table>
