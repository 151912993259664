<!-- Toolbar -->
<div id="toolbar" role="banner" [class]="{'menu-open': menuOpen, 'menu-closed':menuClosed}">
  <div class="grid"><a id="logo" [routerLink]="''">
    <img src="../assets/logo.png" alt="Glücksgriff Logo"/>
  </a>
    <d [routerLink]="menuLinks.home" id="name-container">
    <span id="name">Glücksgriff</span>
    <span id="slogan">
     - der soziale Kreislauf e.V.
    </span>
    </d>
    <menu>
      <li routerLinkActive="active"><a [routerLink]="menuLinks.aboutUs" routerLinkActive="active">Über Uns</a></li>
      <li routerLinkActive="active"><a [routerLink]="menuLinks.howItWorks" routerLinkActive="active">Der Verein</a></li>
      <li routerLinkActive="active"><a [routerLink]="menuLinks.route" routerLinkActive="active">Standorte</a></li>
      <li routerLinkActive="active"><a [routerLink]="menuLinks.projects" routerLinkActive="active">Projekte</a></li>
      <li routerLinkActive="active"><a [routerLink]="menuLinks.repairCafe" routerLinkActive="active">Repair Cafe</a>
      </li>
      <li routerLinkActive="active"><a [routerLink]="menuLinks.supportUs" routerLinkActive="active">Unterstützen Sie
        uns</a></li>
    </menu>
    <div id="hamburger" (click)="toggleMenu()" [class]="{open: menuOpen}">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</div>
<div id="centerStripe">
  <article mat-app-background>
    <router-outlet></router-outlet>
  </article>
  <footer>
    &copy; Glücksgriff - der soziale Kreislauf e.V. {{ currentYear }}
    <div id="footerLinks">
      <a *ngIf="!loggedIn" [routerLink]="menuLinks.login">Anmelden</a><a (click)="logout()"
                                                                         *ngIf="loggedIn">Abmelden</a>
      <ng-container *ngIf="loggedIn"><a [routerLink]="menuLinks.editOffer">Aktuelles Angebot bearbeiten</a>
      </ng-container>
      <a [routerLink]="menuLinks.contact">Kontakt</a>
      <a [routerLink]="menuLinks.impress">Impressum</a>
      <a [routerLink]="menuLinks.dataPrivacy">Datenschutz</a>
    </div>
  </footer>
</div>
<!--<div id="rings"></div>-->
