<app-album [album]="album"></app-album>
<p style="text-align: center;">
  Bei uns können <strong>ALLE</strong> gut und günstig einkaufen! Wir sind fast täglich für sie da.
</p>
<div *ngIf="offer" id="offer">
  <h2>Aktuelles</h2>
  <h3>{{ offer.title }}</h3>
  <div style="font-style: italic; text-align: right;">
    <p *ngIf="!!offer.validUntil && !!offer.validFrom">Vom {{ offer.validFrom | date }} bis
      zum {{ offer.validUntil | date }}
    </p>
    <p *ngIf="!offer.validUntil && offer.validFrom">Ab {{ offer.validFrom | date }}</p>
    <p *ngIf="offer.validUntil && !offer.validFrom">Bis {{ offer.validUntil }}</p>
  </div>
  <div [innerHTML]="offer.htmlContent"></div>
</div>
<p></p>
<h2 style="text-align: center;"><strong>Wir freuen uns auf Ihren Besuch in der</strong></h2>
<div style="text-align: center;">
  <p><strong style="font-size: 20px;">Lornsenstrasse 86</strong><br/>
    <b>Verkauf und Warenannahme</b>
  </p>
  <p style="text-align:center;">22869 Schenefeld<br/>
    <a href="phone:04084055220">Tel 040 840 55 220</a>
  </p>
  <h4 style="margin-bottom: 5px;"><strong>Öffnungszeiten</strong></h4>
  <div class="opening-times">
    <table style="margin: auto; text-align: left;">
      <tr>
        <td>Montag bis Samstag</td>
        <td>10-13 Uhr</td>
      </tr>
      <tr>
        <td>Dienstag bis Freitag</td>
        <td>15-18 Uhr</td>
      </tr>
    </table>
  </div>
  <p>Unser Geschäft ist Montag Nachmittag geschlossen!</p>
  <p style="margin-top:20px;"><strong style="font-size: 20px;">Heisterweg 4</strong><br/>
    <b>Warenannahmelager</b>
  </p>
  <p>
    22869 Schenefeld
  </p>
  <h4 style="margin-bottom: 5px;"><strong>Öffnungszeiten</strong></h4>
  <div class="opening-times">
    <table style="margin: auto; text-align: left;">
      <tr>
        <td>Donnerstag und Samstag</td>
        <td>10-13 Uhr</td>
      </tr>
      <tr>
        <td>Freitag</td>
        <td>15-18 Uhr</td>
      </tr>
    </table>
    <b>Warenannahme im Posthof hinter dem Gebäude</b>
  </div>
  <p style="margin-top:20px;"><strong style="font-size: 20px;">Stadtzentrum Schenefeld</strong><br/>
    <b>Nur Verkauf</b>
  </p>
  <p>
    Kiebitzweg 2<br/>
    1. Obergeschoss<br/>
    22869 Schenefeld<br/>
    <a href="phone:015235705504">Tel 0152 3570 5504</a>
  </p>
  <h4 style="margin-bottom: 5px;"><strong>Öffnungszeiten</strong></h4>
  <div class="opening-times">
    <table style="margin: auto; text-align: left;">
      <tr>
        <td>Donnerstag und Samstag</td>
        <td>10-13 Uhr</td>
      </tr>
      <tr>
        <td>Freitag</td>
        <td>15-18 Uhr</td>
      </tr>
    </table>
  </div>
</div>
