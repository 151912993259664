import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Offer, OfferApiClient} from "../../../api";
import {map} from "rxjs/operators";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {TitleService} from "../../services/title.service";
import {links} from "../../app-routing.module";

@Component({
  selector: 'app-edit-offer',
  templateUrl: './edit-offer.component.html',
  styleUrls: ['./edit-offer.component.scss']
})
export class EditOfferComponent {
  isProcessing = false;

  readonly formControls = {
    title: new FormControl('', [Validators.required]),
    content: new FormControl('', [Validators.required]),
    startDate: new FormControl('', [Validators.required]),
    endDate: new FormControl('', [Validators.required])
  }
  private readonly _offerApiClient: OfferApiClient;
  private _offerId: number | undefined;
  private readonly _router: Router;

  readonly form = new FormGroup(this.formControls);
  readonly editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: '',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  constructor(offerApiClient: OfferApiClient, router: Router, titleService: TitleService, activatedRoute: ActivatedRoute) {
    this._offerApiClient = offerApiClient;
    this._router = router;
    activatedRoute.params
      .pipe(
        map((p) => {
          return p.id as string;
        }),
        map(id => {
          try {
            const parsedId = parseInt(id);
            return isNaN(parsedId) ? null : parsedId;
          } catch {
            return null;
          }
        })
      )
      .subscribe(id => this._getOffer(id))
    titleService.setTitle("Angebot bearbeiten")
  }

  private _getOffer(id: number | null): void {
    if (id === null) {
      this._loadOffer({});
      return;
    }
    this.isProcessing = true;
    this._offerApiClient
      .getOffer(id)
      .subscribe({
        next: offer => {
          this._loadOffer(offer);
          this.isProcessing = false;
        },
        complete: () => this.isProcessing = false
      })
  }

  private _loadOffer(offer: Partial<Offer>): void {
    this.formControls.content.setValue(offer.htmlContent || "");
    this.formControls.title.setValue(offer.title || "");
    this.formControls.startDate.setValue(offer.validFrom || "");
    this.formControls.endDate.setValue(offer.validUntil || "");
    this._offerId = offer.id;
  }

  async save() {
    if (!this.form.valid)
      return;
    const id = this._offerId;
    const validUntil = this.formControls.endDate.value;
    const lastEdit = new Date();
    const validFrom = this.formControls.startDate.value;
    const htmlContent = this.formControls.content.value;
    const title = this.formControls.title.value;
    this.isProcessing = true;
    if (typeof id === "number")
      await this._offerApiClient.updateOffer(title, lastEdit, htmlContent, validFrom, validUntil, id).toPromise();
    else
      await this._offerApiClient.createOffer(title, lastEdit, htmlContent, validFrom, validUntil, -1).toPromise();
    await this._router.navigateByUrl(links.editOffer);
  }

}
