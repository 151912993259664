import {Component, OnInit} from '@angular/core';
import {TitleService} from "../../services/title.service";
import {IAlbumImage} from "../album/album.component";

@Component({
             selector: 'app-how-it-works',
             templateUrl: './how-it-works.component.html',
             styleUrls: ['./how-it-works.component.scss']
           })
export class HowItWorksComponent implements OnInit {


  readonly album: IAlbumImage[] = [
    {
      caption: "Sortier- und Lagerhaltung im Postgebäude",
      src: "/team/DSC_0027.JPG"
    },
    {
      caption: "Montagsgruppe in der Lornsenstrasse",
      src: "/team/DSC_0018.JPG"
    },
    {
      caption: "Verkaufsteam Freitagvormittag in der Lornsenstrasse",
      src: "/team/DSC_0033.JPG"
    }
  ]

  constructor(titleService: TitleService) {
    titleService.setTitle("Der Verein")
  }

  ngOnInit(): void {
  }

}
